import React from "react";

import { styled } from "@mui/material/styles";

import { Twitter, LinkedIn } from "@mui/icons-material";
import { useMediaQuery, IconButton, Typography, Link } from "@mui/material";

import { StaticImage } from "gatsby-plugin-image";

const PREFIX = "footer";

const classes = {
  footerBody: `${PREFIX}-footerBody`,
  footerContainer: `${PREFIX}-footerContainer`,
  certificateColumn: `${PREFIX}-certificateColumn`,
  certificateColumnAlt: `${PREFIX}-certificateColumnAlt`,
  certificateImage: `${PREFIX}-certificateImage`,
  altCertificateImage: `${PREFIX}-altCertificateImage`,
  navAndSusContainer: `${PREFIX}-navAndSusContainer`,
  navContainer: `${PREFIX}-navContainer`,
  susContainer: `${PREFIX}-susContainer`,
  addressContainer: `${PREFIX}-addressContainer`,
  linkText: `${PREFIX}-linkText`,
  link: `${PREFIX}-link`,
  text: `${PREFIX}-text`,
  addressWrapper: `${PREFIX}-addressWrapper`,
  addressWrapperAlt: `${PREFIX}-addressWrapperAlt`,
  iconWrapper: `${PREFIX}-iconWrapper`,
};

const Root = styled("div")(({ theme }) => {
  return {
    width: "100vw",
    zIndex: 0,
    background: "#540096",
    padding: 5,
    position: "relative",
    boxShadow: "inset 0px 6px 10px -8px rgb(255 255 255)",
    [`& .${classes.footerBody}`]: {
      width: "100%",
      padding: "15px 20px 0px 20px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column-reverse",
        padding: "8px 5px 0px 5px",
      },
    },
    [`& .${classes.certificateColumn}`]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
      minWidth: 170,
      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
        minWidth: 50,
        marginBottom: 10,
      },
      width: "100%",
    },
    [`& .${classes.certificateColumnAlt}`]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      minWidth: 170,
      margin: "10px 0px",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
        minWidth: 50,
        marginBottom: 10,
      },
    },
    [`& .${classes.certificateImage}`]: {
      width: 125,
      height: 125,
      margin: "4px 15px",
      objectFit: "contain",
      [theme.breakpoints.down("sm")]: {
        width: 80,
        height: 80,
      },
    },
    [`& .${classes.altCertificateImage}`]: {
      width: 125,
      height: 125,
      margin: "-8px 15px 4px 15px",
      objectFit: "contain",
      [theme.breakpoints.down("sm")]: {
        width: 80,
        height: 80,
        margin: "4px 15px",
      },
    },
    [`& .${classes.navAndSusContainer}`]: {
      margin: "0px 10px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      padding: "0px 10px 10px 10px",
      width: "100%",
    },
    [`& .${classes.navContainer}`]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px 5px",
      width: "100%",
      // borderBottom: "1px solid silver",
    },
    [`& .${classes.susContainer}`]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px",
    },
    [`&.${classes.addressContainer}`]: {
      minWidth: 160,
      margin: "10px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        minWidth: 100,
      },
    },
    [`& .${classes.linkText}`]: {
      margin: "5px 0px",
      textAlign: "center",
      lineHeight: 1.5,
      color: "white",
    },
    [`& .${classes.link}`]: {
      margin: "0px 5px",
      color: "white",
      textDecoration: "underline",
    },
    [`& .${classes.text}`]: {
      textAlign: "center",
      color: "white",
    },
    [`& .${classes.addressWrapper}`]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "space-between",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
      },
    },
    [`& .${classes.addressWrapperAlt}`]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
      },
    },
    [`& .${classes.iconWrapper}`]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
      },
      borderBottom: "1px solid silver",
    },
  };
});

const AddressContainer = styled("div")(({ theme }) => {
  return {
    minWidth: 160,
    margin: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      minWidth: 100,
    },
    [`& .${classes.text}`]: {
      textAlign: "center",
      color: "white",
    },
  };
});

const Address = React.memo(
  ({ title, secondTitle, streetAddress, city, postCode, country }) => {
    return (
      <AddressContainer>
        <Typography
          variant={"subtitle1"}
          color={"primary"}
          style={{
            fontWeight: "bold",
            marginBottom: 5,
            textAlign: "center",
            color: "white",
            lineHeight: 1,
          }}
        >
          {title}
        </Typography>
        {!!secondTitle && (
          <Typography
            variant={"subtitle1"}
            color={"primary"}
            style={{
              fontWeight: "bold",
              marginBottom: 5,
              textAlign: "center",
              color: "white",
              lineHeight: 1,
            }}
          >
            {secondTitle}
          </Typography>
        )}
        <Typography className={classes.text}>{streetAddress}</Typography>
        <Typography className={classes.text}>{city}</Typography>
        <Typography className={classes.text}>{postCode}</Typography>
        <Typography className={classes.text}>{country}</Typography>
      </AddressContainer>
    );
  }
);

export default function Footer(props) {
  const small = useMediaQuery("(max-width:680px)");

  return (
    <Root>
      <div className={classes.footerBody}>
        <div className={classes.navAndSusContainer}>
          <div className={classes.navContainer}>
            <Typography
              variant={"h5"}
              color={"primary"}
              style={{
                fontWeight: "bold",
                marginBottom: 5,
                color: "white",
              }}
            >
              Quick Navigation
            </Typography>
            <Typography className={classes.linkText}>
              <Link className={classes.link} href={"/"}>
                Home
              </Link>{" "}
              -{" "}
              <Link className={classes.link} href={"/about-us"}>
                About Us
              </Link>{" "}
              -{" "}
              <Link className={classes.link} href={"/specifications"}>
                Specifications
              </Link>{" "}
            </Typography>
            <Typography className={classes.linkText}>
              {/* <Link style={{ margin: "0px 5px" }}>Press</Link> -{" "} */}
              <Link className={classes.link} href={"/sustainability"}>
                Sustainability
              </Link>{" "}
              -{" "}
              <Link className={classes.link} href={"/careers"}>
                Careers
              </Link>{" "}
              -{" "}
              <Link className={classes.link} href={"/contact-us"}>
                Contact Us
              </Link>
            </Typography>
          </div>
          <div className={classes.iconWrapper}>
            <IconButton
              color={"white"}
              size={small ? "medium" : "large"}
              href={"https://twitter.com/utterberry"}
              aria-label='Twitter'
            >
              <Twitter fontSize={small ? "medium" : "large"} />
            </IconButton>
            <IconButton
              color={"white"}
              size={small ? "medium" : "large"}
              href={"https://www.linkedin.com/company/utterberry-ltd"}
              aria-label='Linked In'
            >
              <LinkedIn fontSize={small ? "medium" : "large"} />
            </IconButton>
          </div>
          {/* <div className={classes.susContainer}>
            <Typography
              variant={"h5"}
              color={"primary"}
              style={{
                fontWeight: "bold",
                marginBottom: 5,
                color: "white",
              }}
            >
              Sustainability
            </Typography>
            <Typography className={classes.text}>
              At UtterBerry we are committed to green and sustainable action in
              everything we do. Internally we make sure to minimise all waste,
              whether that be recycling, reusing, or repurposing. Importantly we
              also focus on the impact of our data processing, only saving data
              that is essential and relevant or if a client requests. As a
              result we maximise our reduction in CO2 emissions within our data
              retention system.
            </Typography>
          </div> */}
          <div className={classes.addressWrapperAlt}>
            <a href={"/contact-us"} style={{ textDecoration: "none" }} aria-label="Contact Page">
              <Address
                title={"UtterBerry London"}
                streetAddress={"124 City Road,"}
                city={"London,"}
                postCode={"EC1V 2NX"}
                country={"England"}
              />
            </a>
            <a href={"/contact-us"} style={{ textDecoration: "none" }} aria-label="Contact Page">
              <Address
                title={"UtterBerry Leeds"}
                streetAddress={"32 Sovereign St,"}
                city={"Leeds,"}
                postCode={"LS1 4BJ"}
                country={"England"}
              />
            </a>
          </div>
          <div className={classes.certificateColumnAlt}>
            <StaticImage
              src={"../images/badge-images/9001.jpg"}
              className={classes.certificateImage}
              objectFit={"contain"}
              placeholder={"#540096"}
              alt={"ISO 9001"}
              width={125}
              height={125}
              loading={"eager"}
            />
            <StaticImage
              src={"../images/badge-images/13485.jpg"}
              className={classes.certificateImage}
              objectFit={"contain"}
              placeholder={"#540096"}
              alt={"ISO 13485"}
              width={125}
              height={94}
              loading={"eager"}
            />
            <StaticImage
              src={"../images/badge-images/27001.jpg"}
              className={classes.certificateImage}
              objectFit={"contain"}
              placeholder={"#540096"}
              alt={"ISO 27001"}
              width={125}
              height={125}
              loading={"eager"}
            />
          </div>
          <div
            style={{
              padding: "0px 18px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              variant={"caption"}
              style={{
                color: "white",
                textAlign: "center",
              }}
            >
              UtterBerry*, Utter* and UB* are trademarks of UtterBerry Ltd. in
              the United Kingdom and/or other countries. All trademarks are the
              property of their respective owners. Corporate headquarters and
              logistics center in London, England. © 2022 UtterBerry. All rights
              reserved.
            </Typography>
          </div>
        </div>
      </div>
    </Root>
  );
}
