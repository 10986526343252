import React, { useEffect, useState } from "react";
import "./layout.css";

import {
  Menu as MenuIcon,
  // Home,
  Close,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import styledOri from "styled-components";

import Footer from "./footer";

import {
  Button,
  useMediaQuery,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

import { StaticImage } from "gatsby-plugin-image";

// random branches for drawer
import BranchOne from "../images/branches/Thing 1.svg";
import BranchTwo from "../images/branches/Thing 2.svg";
import BranchThree from "../images/branches/Thing 9.svg";
import BranchFour from "../images/branches/Thing 10.svg";
import BranchFive from "../images/branches/Thing 6.svg";

// burger icons
import AboutUs from "../images/menu-icons/about-us-v2.svg";
import Careers from "../images/menu-icons/careers-v2.svg";
import ContactUs from "../images/menu-icons/contact-us-v2.svg";
import Home from "../images/menu-icons/home-v2.svg";
import Specifications from "../images/menu-icons/specifications-v2.svg";
import Sustainability from "../images/sustainability-images/utterberry.webp";

const PREFIX = "index";

const classes = {
  windowContainer: `${PREFIX}-windowContainer`,
  root: `${PREFIX}-root`,
  menuButton: `${PREFIX}-menuButton`,
  title: `${PREFIX}-title`,
  logoImage: `${PREFIX}-logoImage`,
  imageContainer: `${PREFIX}-imageContainer`,
  headerButton: `${PREFIX}-headerButton`,
  toolbar: `${PREFIX}-toolbar`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  iconImage: `${PREFIX}-iconImage`,
  iconContainer: `${PREFIX}-iconContainer`,
  footerContainer: `${PREFIX}-footerContainer`,
  footerLogo: `${PREFIX}-footerLogo`,
  branch: `${PREFIX}-branch`,
  drawerFooter: `${PREFIX}-drawerFooter`,
  bottomImage: `${PREFIX}-bottomImage`,
  listItem: `${PREFIX}-listItem`,
  drawerHeader: `${PREFIX}-drawerHeader`,
  certificateColumn: `${PREFIX}-certificateColumn`,
  certificateImage: `${PREFIX}-certificateImage`,
  noHyperLink: `${PREFIX}-noHyperLink`,
};

const Root = styled("div")(({ theme }) => {
  return {
    width: "100vw",
    [`&.${classes.root}`]: {
      borderBottom: "1px solid rgba(0,0,0,0.06)",
      height: 65,
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        height: 57,
      },
      "@media (max-height: 500px)": {
        height: 49,
      },
      width: "100vw",
      overflow: "hidden",
    },
    [`& .${classes.menuButton}`]: {
      marginLeft: theme.spacing(2),
    },
    [`& .${classes.title}`]: {
      flexGrow: 1,
    },
    [`& .${classes.logoImage}`]: {
      maxHeight: 100,
      minHeight: 25,
      height: 100,
      width: 180,
      objectFit: "contain",
      [theme.breakpoints.down("sm")]: {
        maxHeight: 75,
        height: 75,
        width: 150,
      },
    },
    [`& .${classes.headerButton}`]: {
      fontFamily: "Roboto, sans-serif, serif",
    },
    [`& .${classes.toolbar}`]: theme.mixins.toolbar,
    [`& .${classes.drawer}`]: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
      overflow: "hidden",
    },
    [`& .${classes.drawerPaper}`]: {
      width: drawerWidth,
      overflow: "hidden",
    },
    [`& .${classes.iconImage}`]: {
      width: "80%",
    },
    [`& .${classes.iconContainer}`]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      padding: "10px 10px",
    },
    [`& .${classes.footerContainer}`]: {
      width: "100vw",
      padding: "15px 20px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderTop: "1px solid #540096",
      zIndex: 100,
      background: "white",
    },
    [`& .${classes.footerLogo}`]: {
      maxWidth: 200,
    },
    [`& .${classes.branch}`]: {
      position: "absolute",
      right: 0,
      top: "70%",
    },
    [`& .${classes.drawerFooter}`]: {
      flexGrow: 1,
      position: "absolute",
      bottom: 0,
      left: 0,
      zIndex: -1,
    },
    [`& .${classes.bottomImage}`]: {
      width: "180%",
      transform: "translate(-25%, 10%)",
    },
    [`& .${classes.listItem}`]: {
      backgroundColor: "white",
    },
    [`& .${classes.drawerHeader}`]: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      padding: 5,
    },
    [`& .${classes.certificateColumn}`]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
      width: 80,
    },
    [`& .${classes.certificateImage}`]: {
      width: "100%",
      margin: "10px",
    },
    [`& .${classes.noHyperLink}`]: {
      color: "inherit",
      textDecoration: "inherit",
    },
  };
});

const NavDrawer = styled("nav")(({ theme }) => {
  return {
    [`& .${classes.menuButton}`]: {
      marginLeft: theme.spacing(2),
    },
    [`& .${classes.title}`]: {
      flexGrow: 1,
    },
    [`& .${classes.logoImage}`]: {
      maxHeight: 35,
      minHeight: 25,
      [theme.breakpoints.down("sm")]: {
        maxHeight: 25,
      },
    },
    [`& .${classes.headerButton}`]: {
      fontFamily: "Roboto, sans-serif, serif",
    },
    [`& .${classes.toolbar}`]: theme.mixins.toolbar,
    [`& .${classes.drawer}`]: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
      overflow: "hidden",
    },
    [`& .${classes.drawerPaper}`]: {
      width: drawerWidth,
      overflow: "hidden",
    },
    [`& .${classes.iconImage}`]: {
      width: "67%",
    },
    [`& .${classes.iconContainer}`]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      padding: "10px 10px",
    },
    [`& .${classes.branch}`]: {
      position: "absolute",
      right: 0,
      top: "70%",
    },
    [`& .${classes.drawerFooter}`]: {
      flexGrow: 1,
      position: "absolute",
      bottom: 0,
      left: 0,
      zIndex: -1,
      overflow: "hidden",
    },
    [`& .${classes.bottomImage}`]: {
      width: "180%",
      transform: "translate(-22%, 10%)",
    },
    [`& .${classes.listItem}`]: {
      backgroundColor: "white",
      overflow: "hidden",
    },
    [`& .${classes.drawerHeader}`]: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      padding: 5,
    },
    [`& .${classes.noHyperLink}`]: {
      color: "inherit",
      textDecoration: "inherit",
    },
  };
});

const drawerWidth = 240;

const HeaderContainer = styledOri.header`
  box-shadow: rgb(0 0 0 / 20%) 0px 1px 2px -1px,
    rgb(0 0 0 / 14%) 0px 1px 3px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
  position: fixed;
  top: 0;
  right: 0;
  left: auto;
  z-index: 1100;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.9);
  max-height: 64px;
  min-height: 64px;
  justify-content: center;

  @supports (
    (-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))
  ) {
    background-color: rgba(200, 200, 200, 0.2);
    background-color: rgba(255, 255, 255, 0.72);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
  }

  @media (max-width: 600px) {
    min-height: 56px;
  }
`;

const ImageContainer = styledOri.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  max-height: 35px;
  min-height: 25px;
  width: 180px;
  overflow: hidden;

  @media (max-width: 600px) {
    max-height: 25px;
    width: 150px;
  }
`;

const ToolBarAlt = styledOri.div`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 56px;
  -webkit-box-align: center;

  @media (min-width: 600px) {
    min-height: 64px;
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const branchMap = [
  {
    branch: BranchOne,
    transform: "translateY(-50%) rotate(90deg)",
    width: "15px",
  },
  {
    branch: BranchTwo,
    transform: "translateY(-50%) rotate(90deg)",
    width: "9px",
  },
  {
    branch: BranchThree,
    transform: "translateY(-50%) rotate(90deg)",
    width: "12px",
  },
  {
    branch: BranchFour,
    transform: "translateY(-50%) rotate(90deg)",
    width: "18px",
  },
  {
    branch: BranchFive,
    transform: "translateY(-50%) scaleY(-1) rotate(90deg)",
    width: "14px",
  },
  {
    branch: BranchFive,
    transform: "translateY(-50%) rotate(90deg)",
    width: "14px",
  },
];

export default function Layout({ children }) {
  const breakToMenu = useMediaQuery("(max-width:775px)");
  const [mounted, setMounted] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    setMounted(true);

    window.scrollTo(0, 0);

    return () => {
      setMounted(false);
    };
  }, []);

  return (
    <Root>
      <div className={classes.root}>
        <HeaderContainer>
          <ToolBarAlt>
            <ImageContainer>
              <a href={"/"}>
                <StaticImage
                  src={"../images/utterberry-logo-standard.webp"}
                  className={classes.logoImage}
                  objectFit={"contain"}
                  loading={"eager"}
                  width={180}
                  height={100}
                  placeholder={"white"}
                  alt={"UtterBerry Logo"}
                />
              </a>
            </ImageContainer>
            {!breakToMenu && mounted && (
              <div>
                <Button
                  color='primary'
                  classes={{
                    text: classes.headerButton,
                  }}
                  href={"/"}
                >
                  Home
                </Button>
                <Button
                  color='primary'
                  classes={{
                    text: classes.headerButton,
                  }}
                  href={"/about-us"}
                >
                  About
                </Button>
                <Button
                  color='primary'
                  classes={{
                    text: classes.headerButton,
                  }}
                  href={"/specifications"}
                >
                  Specifications
                </Button>
                <Button
                  color='primary'
                  classes={{
                    text: classes.headerButton,
                  }}
                  href={"/careers"}
                >
                  Careers
                </Button>
                <Button
                  color='primary'
                  classes={{
                    text: classes.headerButton,
                  }}
                  href={"/sustainability"}
                >
                  Sustainability
                </Button>
                <Button
                  color='primary'
                  classes={{
                    text: classes.headerButton,
                  }}
                  href={"/contact-us"}
                >
                  Contact
                </Button>
              </div>
            )}
            {breakToMenu && mounted && (
              <div>
                <IconButton
                  edge='start'
                  className={classes.menuButton}
                  color='primary'
                  aria-label='menu'
                  onClick={() => {
                    setOpenDrawer(true);
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  anchor={"right"}
                  open={openDrawer}
                  onClose={() => {
                    setOpenDrawer(false);
                  }}
                  style={{
                    overflow: "hidden",
                  }}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                >
                  <NavDrawer>
                    <div className={classes.drawerHeader}>
                      <IconButton
                        edge='start'
                        color='primary'
                        aria-label='menu'
                        onClick={() => {
                          setOpenDrawer(false);
                        }}
                      >
                        <Close />
                      </IconButton>
                    </div>
                    <div className={classes.iconContainer}>
                      <StaticImage
                        src={"../images/utterberry-logo-standard.webp"}
                        className={classes.iconImage}
                        objectFit={"contain"}
                        placeholder={"white"}
                        alt={"UtterBerry Logo"}
                      />
                    </div>
                    <List
                      style={{
                        backgroundColor: "white",
                        padding: 0,
                      }}
                    >
                      <a href={"/"} className={classes.noHyperLink}>
                        <ListItem
                          button
                          key={"Home"}
                          divider
                          className={classes.listItem}
                        >
                          <ListItemIcon
                            style={{
                              minWidth: 30,
                              maxHeight: 30,
                              marginRight: 10,
                            }}
                          >
                            <img
                              src={Home}
                              alt={"Home"}
                              style={{
                                objectFit: "contain",
                                width: "30px",
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={"Home"} />
                          <img
                            src={branchMap[0]?.branch ?? null}
                            className={classes.branch}
                            style={{
                              width: branchMap[0]?.width,
                              transform: branchMap[0]?.transform,
                            }}
                            alt={"Drawer Branch 1"}
                          />
                        </ListItem>
                      </a>
                      <a href={"/about-us"} className={classes.noHyperLink}>
                        <ListItem
                          button
                          key={"About Us"}
                          divider
                          className={classes.listItem}
                        >
                          <ListItemIcon
                            style={{
                              minWidth: 30,
                              maxHeight: 30,
                              marginRight: 10,
                            }}
                          >
                            <img
                              src={AboutUs}
                              alt={"About us"}
                              style={{
                                objectFit: "contain",
                                width: "30px",
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={"About Us"} />
                          <img
                            src={branchMap[1]?.branch ?? null}
                            className={classes.branch}
                            style={{
                              width: branchMap[1]?.width,
                              transform: branchMap[1]?.transform,
                            }}
                            alt={"Drawer Branch 2"}
                          />
                        </ListItem>
                      </a>
                      <a
                        href={"/specifications"}
                        className={classes.noHyperLink}
                      >
                        <ListItem
                          button
                          key={"Specifications"}
                          divider
                          className={classes.listItem}
                        >
                          <ListItemIcon
                            style={{
                              minWidth: 30,
                              maxHeight: 30,
                              marginRight: 10,
                            }}
                          >
                            <img
                              src={Specifications}
                              alt={"Specifications"}
                              style={{
                                objectFit: "contain",
                                width: "30px",
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={"Specifications"} />
                          <img
                            src={branchMap[2]?.branch ?? null}
                            className={classes.branch}
                            style={{
                              width: branchMap[2]?.width,
                              transform: branchMap[2]?.transform,
                            }}
                            alt={"Drawer Branch 3"}
                          />
                        </ListItem>
                      </a>
                      <a href={"/careers"} className={classes.noHyperLink}>
                        <ListItem
                          button
                          key={"Careers"}
                          divider
                          className={classes.listItem}
                        >
                          <ListItemIcon
                            style={{
                              minWidth: 30,
                              maxHeight: 30,
                              marginRight: 10,
                            }}
                          >
                            <img
                              src={Careers}
                              alt={"Careers"}
                              style={{
                                objectFit: "contain",
                                width: "30px",
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={"Careers"} />
                          <img
                            src={branchMap[3]?.branch ?? null}
                            className={classes.branch}
                            style={{
                              width: branchMap[3]?.width,
                              transform: branchMap[3]?.transform,
                            }}
                            alt={"Drawer Branch 4"}
                          />
                        </ListItem>
                      </a>
                      <a
                        href={"/sustainability"}
                        className={classes.noHyperLink}
                      >
                        <ListItem
                          button
                          key={"Sustainability"}
                          divider
                          className={classes.listItem}
                        >
                          <ListItemIcon
                            style={{
                              minWidth: 30,
                              maxWidth: 30,
                              maxHeight: 30,
                              marginRight: 10,
                            }}
                          >
                            <img
                              src={Sustainability}
                              alt={"Sustainability"}
                              style={{
                                objectFit: "contain",
                                width: "100%",
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={"Sustainability"} />
                          <img
                            src={branchMap[4]?.branch ?? null}
                            className={classes.branch}
                            style={{
                              width: branchMap[4]?.width,
                              transform: branchMap[4]?.transform,
                            }}
                            alt={"Drawer Branch 5"}
                          />
                        </ListItem>
                      </a>
                      <a href={"/contact-us"} className={classes.noHyperLink}>
                        <ListItem
                          button
                          key={"Contact Us"}
                          divider
                          className={classes.listItem}
                        >
                          <ListItemIcon
                            style={{
                              minWidth: 30,
                              maxHeight: 30,
                              marginRight: 10,
                            }}
                          >
                            <img
                              src={ContactUs}
                              alt={"Contact Us"}
                              style={{ width: "30px" }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={"Contact Us"} />
                          <img
                            src={branchMap[5]?.branch ?? null}
                            className={classes.branch}
                            style={{
                              width: branchMap[5]?.width,
                              transform: branchMap[5]?.transform,
                            }}
                            alt={"Drawer Branch 6"}
                          />
                        </ListItem>
                      </a>
                    </List>
                    <div className={classes.drawerFooter}>
                      <StaticImage
                        src={
                          "../images/spec-images/environment-background.webp"
                        }
                        className={classes.bottomImage}
                        objectFit={"contain"}
                        placeholder={"white"}
                        alt={"Drawer Environment Background Image"}
                      />
                    </div>
                  </NavDrawer>
                </Drawer>
              </div>
            )}
          </ToolBarAlt>
        </HeaderContainer>
      </div>
      {children}
      <Footer />
    </Root>
  );
}
