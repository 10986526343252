import { red } from "@mui/material/colors";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

// A custom theme for this app
let theme = createTheme({
  palette: {
    primary: {
      main: "#540096",
    },
    secondary: {
      main: "#770048",
    },
    white: {
      main: "#fff",
    },
    error: {
      main: red.A400,
    },
  },
});

theme = responsiveFontSizes(theme);

theme.typography.p = {
  fontSize: "10px",
  fontFamily: "'Roboto',sans-serif,serif",
  "@media (min-width:1000px)": {
    fontSize: "14px",
  },
};

// theme.typography.h1 = {
//   fontFamily: `'Avenir Black', 'Arial Black', sans-serif`,
// };

// theme.typography.h2 = {
//   fontFamily: `'Avenir Black', 'Arial Black', sans-serif`,
// };

// theme.typography.h3 = {
//   fontFamily: `'Avenir Black', 'Arial Black', sans-serif`,
// };

theme.typography.h4 = {
  fontSize: "1.3rem",
  fontWeight: "400",
  // fontFamily: `'Avenir Black', 'Arial Black', sans-serif`,
  [theme.breakpoints.up("md")]: {
    fontSize: "2rem",
  },
};

// theme.typography.h5 = {
//   fontFamily: `'Avenir Black', 'Arial Black', sans-serif`,
// };

theme.typography.body1 = {
  fontSize: "0.8rem",
  fontFamily: "Roboto,sans-serif,serif",
  [theme.breakpoints.up("md")]: {
    fontSize: "1rem",
  },
};

export default theme;
